import React, { useState } from "react"

import colors from "../../lib/colors"
import { LeaderboardGroup, LeaderboardTime, LeaderboardType } from "../../hasura/queries/user"
import { Tooltip } from "reactstrap"

interface Props {
  hasClassroom: boolean
  accuracyLocked: boolean
  leaderboardGroup: LeaderboardGroup
  leaderboardTime: LeaderboardTime
  leaderboardType: LeaderboardType
  setLeaderboardGroup: (leaderboardGroup: LeaderboardGroup) => void
  setLeaderboardTime: (leaderboardTime: LeaderboardTime) => void
  setLeaderboardType: (leaderboardType: LeaderboardType) => void
}

export default function LeaderboardMenu(props: Props) {
  const [displayAccuracyLockedTooltip, setDisplayAccuracyLockedTooltip] = useState(false)

  return (
    <div style={{ gap: "10px 40px" }} className="mx-auto flex-center text-s flex-wrap bg--white width-fit-content">
      {props.hasClassroom && (
        <div style={{ gap: "8px" }} className="d-flex justify-content-end align-items-center">
          <p
            onClick={() => props.setLeaderboardGroup(LeaderboardGroup.Global)}
            role="button"
            className={`bold m-0 transition-m ${props.leaderboardGroup === LeaderboardGroup.Global ? "primary" : "gray4"}`}
          >
            World
          </p>

          <div style={{ height: "5px", width: "5px", borderRadius: "10px", backgroundColor: colors.gray6 }} />

          <p
            onClick={() => props.setLeaderboardGroup(LeaderboardGroup.Class)}
            role="button"
            className={`text-right bold m-0 transition-m ${props.leaderboardGroup === LeaderboardGroup.Class ? "primary" : "gray4"}`}
          >
            School
          </p>
        </div>
      )}

      <div style={{ gap: "8px" }} className="d-flex justify-content-end align-items-center">
        <p
          onClick={() => props.setLeaderboardTime(LeaderboardTime.Weekly)}
          role="button"
          className={`text-right bold m-0 transition-m ${props.leaderboardTime === LeaderboardTime.Weekly ? "primary" : "gray4"}`}
        >
          Weekly
        </p>

        <div style={{ height: "5px", width: "5px", borderRadius: "10px", backgroundColor: colors.gray6 }} />

        <p
          onClick={() => props.setLeaderboardTime(LeaderboardTime.Eternity)}
          role="button"
          className={`bold m-0 transition-m ${props.leaderboardTime === LeaderboardTime.Eternity ? "primary" : "gray4"}`}
        >
          All Time
        </p>
      </div>

      <div style={{ gap: "8px" }} className="d-flex justify-content-end align-items-center">
        <p
          onClick={() => props.setLeaderboardType(LeaderboardType.Stars)}
          role="button"
          className={`text-right bold m-0 transition-m ${props.leaderboardType === LeaderboardType.Stars ? "primary" : "gray4"}`}
        >
          Stars
        </p>

        <div style={{ height: "5px", width: "5px", borderRadius: "10px", backgroundColor: colors.gray6 }} />

        <p
          onPointerLeave={() => setDisplayAccuracyLockedTooltip(false)}
          onPointerEnter={() => {
            if (props.accuracyLocked) setDisplayAccuracyLockedTooltip(true)
          }}
          onClick={() => {
            if (!props.accuracyLocked) props.setLeaderboardType(LeaderboardType.Accuracy)
          }}
          className={`bold m-0 transition-m ${props.leaderboardType === LeaderboardType.Accuracy ? "primary" : "gray4"}`}
          id="AccuracyTooltip"
          role={props.accuracyLocked ? "" : "button"}
        >
          Accuracy{props.accuracyLocked ? " 🔒" : ""}
        </p>

        {displayAccuracyLockedTooltip && (
          <Tooltip arrowClassName="tooltip-arrow-light" fade={false} innerClassName="tooltip-inner-light" isOpen target="AccuracyTooltip">
            <p className="text-xs m-0">Accuracy leaderboards unlocked at level 10.</p>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
