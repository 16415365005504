import React, { useEffect, useState } from "react"
import { Button, Spinner } from "reactstrap"
import last from "lodash/last"
import { useDispatch, useSelector } from "react-redux"

import LeaderboardMenu from "./menu"
import colors from "../../lib/colors"
import { LeaderboardGroup, LeaderboardTime, LeaderboardType } from "../../hasura/queries/user"
import { QueryName } from "../../hasura/queryNames"
import { getMedal, OLOG, withCommas } from "../../lib/helpers"
import { usePrevious } from "../../hooks/usePrevious"
import { UserEvent } from "../../lib/userEventTypes"

import {
  classLeaderboardsAroundUserAction,
  globalLeaderboardsAroundUserAction,
  insertUserEventAction,
  unsetLeaderboardsAction,
  userSelector,
  UserState,
} from "../../hasura/slices/user"

// @ts-ignore
import accuracyIcon from "../../lib/images/target.png"
// @ts-ignore
import starIcon from "../../lib/images/star.svg"
// @ts-ignore
import accuracyWhiteIcon from "../../lib/images/target-white.png"
// @ts-ignore
import starWhiteIcon from "../../lib/images/star-white.svg"
// @ts-ignore
import globeIcon from "../../lib/images/globe.png"
// @ts-ignore
import schoolIcon from "../../lib/images/school.png"

interface Props {
  hasClassroom: boolean
  accuracyLocked: boolean
  leaderboardGroup: LeaderboardGroup
  leaderboardType: LeaderboardType
  leaderboardTime: LeaderboardTime
  setLeaderboardGroup: (leaderboardGroup: LeaderboardGroup) => void
  setLeaderboardTime: (leaderboardTime: LeaderboardTime) => void
  setLeaderboardType: (leaderboardType: LeaderboardType) => void
}

export default function LeaderboardComponent(props: Props) {
  const dispatch = useDispatch()

  const [position, setPosition] = useState<number | undefined>()
  const [justScrolledUp, setJustScrolledUp] = useState<boolean | undefined>()

  const { user, accessToken, globalLeaderboard, classLeaderboard, isQuerying }: UserState = useSelector(userSelector)

  const isGlobal = props.leaderboardGroup === LeaderboardGroup.Global
  const isStars = props.leaderboardType === LeaderboardType.Stars

  const leaderboard = isGlobal ? globalLeaderboard : classLeaderboard
  const previousLeaderboard = usePrevious(leaderboard)
  const previousLeaderboardType = usePrevious(props.leaderboardType)
  const previousLeaderboardTime = usePrevious(props.leaderboardTime)

  /*
    Effects
  */

  useEffect(() => {
    if (!user || !accessToken) return

    const usePosition = previousLeaderboardType === props.leaderboardType && previousLeaderboardTime === props.leaderboardTime

    const fn = isGlobal ? globalLeaderboardsAroundUserAction : classLeaderboardsAroundUserAction

    dispatch(fn(accessToken, props.leaderboardType, props.leaderboardTime, user.id, usePosition ? position : undefined))
  }, [user, position, props.leaderboardType, props.leaderboardTime, props.leaderboardGroup])

  useEffect(() => {
    if (!user || !accessToken) return

    const event = {
      user_id: user.id,
      user_event_type_id: UserEvent.ViewedLeaderboards,
      additional_data: {
        leaderboard_group: props.leaderboardGroup,
        leaderboard_type: props.leaderboardType,
        leaderboard_time: props.leaderboardTime,
      },
    }
    dispatch(insertUserEventAction(accessToken, event))
  }, [user, props.leaderboardGroup])

  useEffect(() => {
    dispatch(unsetLeaderboardsAction())
    setPosition(undefined)
  }, [props.leaderboardType, props.leaderboardTime, props.leaderboardGroup])

  useEffect(() => {
    if (!leaderboard || !user) return

    if (leaderboard.name === previousLeaderboard?.name && position) {
      OLOG(`loaded ${leaderboard.positions[0].position} to ${last(leaderboard.positions)?.position}`)
      const id = leaderboard.positions.find((p) => p.position === position)?.id
      if (id) scrollToUser(id, justScrolledUp)
    } else {
      scrollToUser(user.id, true)
    }
  }, [leaderboard, user])

  /*
    Methods
  */

  const isAtTop = (e: Element) => Math.abs(e.scrollTop) < 10

  const isAtBottom = (e: Element) => Math.abs(e.scrollHeight - e.scrollTop - e.clientHeight) < 10

  const handleScroll = (e: any) => {
    if (!leaderboard?.positions.length) return

    const topPositionsExist = leaderboard.positions[0].position !== 1
    const bottomPositionsExist = last(leaderboard.positions)?.position !== leaderboard.count

    if (isAtTop(e.target) && topPositionsExist) {
      setPosition(leaderboard.positions[0].position || undefined)
      setJustScrolledUp(true)
    }

    if (isAtBottom(e.target) && bottomPositionsExist) {
      setPosition(last(leaderboard.positions)?.position || undefined)
      setJustScrolledUp(false)
    }
  }

  const scrollToUser = (id: string, top?: boolean) => {
    OLOG(`scroll to user ${id} ${top}`)
    const row = document.getElementById(`${id}-${isGlobal}`)
    if (row) {
      document.getElementById(`${id}-${isGlobal}`)?.scrollIntoView(top)
    } else {
      document.getElementById(`leaderboard-${props.leaderboardGroup}`)?.scrollTo(0, 0)
    }
  }

  const topPositionInView = leaderboard?.positions.some((p) => p.position === 1)

  return (
    <div className="flex-center flex-grow-1">
      <div className="flex-even d-flex justify-content-end">
        {(leaderboard?.positions.length || 0) > 0 && (
          <Button
            onClick={() => setPosition(1)}
            className={`min-width-150px mr-2 transition-s ${topPositionInView ? "opacity-0 pe-none" : "opacity-1"}`}
            color="primary"
          >
            Go to Top
          </Button>
        )}
      </div>

      <div
        className="w-100 d-flex align-items-center flex-column position-relative border rounded bw-2 m-0-auto width-900px bg--white"
        style={{ height: "75vh", overflowY: "scroll" }}
      >
        {isQuerying[QueryName.Leaderboards] && (
          <div className="w-100 h-100 flex-center position-absolute" style={{ backgroundColor: "rgba(255,255,255,0.5)" }}>
            <Spinner color="primary" />
          </div>
        )}

        <div className="d-flex align-items-start justify-content-center position-relative w-100 border-bottom bw-2 py-3">
          <LeaderboardMenu
            hasClassroom={props.hasClassroom}
            accuracyLocked={props.accuracyLocked}
            leaderboardGroup={props.leaderboardGroup}
            leaderboardTime={props.leaderboardTime}
            leaderboardType={props.leaderboardType}
            setLeaderboardGroup={props.setLeaderboardGroup}
            setLeaderboardTime={props.setLeaderboardTime}
            setLeaderboardType={props.setLeaderboardType}
          />
        </div>

        <div id={`leaderboard-${props.leaderboardGroup}`} onScroll={handleScroll} style={{ overflowY: "scroll", flexGrow: 1 }} className="w-100">
          {(leaderboard?.positions || []).map((l, idx) => {
            const isMe = l.id === user?.id
            const isOdd = idx % 2 === 1

            return (
              <div
                className={`d-flex justify-content-between align-items-start py-1 px-3 w-100 ${isMe ? "text-white" : "gray8"}`}
                key={idx}
                id={`${l?.id}-${isGlobal}`}
                style={{ backgroundColor: isMe ? colors.primary : isOdd ? "white" : colors.gray }}
              >
                <div className={`d-flex align-items-center single-line`}>
                  <p style={{ minWidth: "80px" }} className="text-s semibold m-0">
                    {getMedal(l.position ? l.position - 1 : 999)} {withCommas(l.position || 0)}
                  </p>

                  <p style={{ width: "150px" }} className="text-s text-left my-0 mr-1 single-line">
                    {l.name}
                  </p>

                  {l.description && l.description !== "My Class" && <p className="text-s text-left m-0 single-line">{l.description}</p>}
                </div>

                <div style={{ float: "right" }} className="d-flex align-items-center justify-content-center mx-2">
                  <p className={`${isMe ? "text-white" : "text--gray7"} bold text-s bold m-0 mr-1`}>
                    {isStars ? withCommas(l.score) : `${Math.round((isGlobal ? l.score / 100 : l.score) * 10) / 10}%`}
                  </p>

                  <img
                    className="icon-xs mb-1"
                    key={idx}
                    src={isStars ? (isMe ? starWhiteIcon : starIcon) : isMe ? accuracyWhiteIcon : accuracyIcon}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex-even" />
    </div>
  )
}
